import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { white } from '../styling/colors'
import { navigate } from '@reach/router'
import { Arrow2Left } from './icons'
import { motion } from 'framer-motion'
import GoBack from './go-back'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: ${white};

  margin-bottom: 24px;
`

export default function ComingSoon() {
  return (
    <Container>
      <Title>Coming Soon!</Title>
      <GoBack color={white} />
    </Container>
  )
}
